const chapters = [
    {
        key: 'Chapter1',
        name: 'Chapter 1',
        questions: [
            {
                question: "What term describes the level of entrepreneurship within a firm?",
                options: [
                    "Corporate Innovation",
                    "Entrepreneurial Spectrum",
                    "Entrepreneurial Intensity",
                    "Firm-Level Dynamics"
                ],
                answer: "Entrepreneurial Intensity"
            },
            {
                question: "Which of the following is NOT a primary characteristic of successful entrepreneurs?",
                options: [
                    "Passion for the business",
                    "Tenacity despite failure",
                    "Gambling tendencies",
                    "Execution intelligence"
                ],
                answer: "Gambling tendencies"
            },
            {
                question: "Which myth about entrepreneurs is based on the belief that they are genetically predisposed to entrepreneurship?",
                options: [
                    "Entrepreneurs Are Born, Not Made",
                    "Entrepreneurs Are Gamblers",
                    "Entrepreneurs Are Motivated Primarily by Money",
                    "Entrepreneurs Should Be Young and Energetic"
                ],
                answer: "Entrepreneurs Are Born, Not Made"
            },
            {
                question: "Which of the following is NOT listed as a key characteristic of startups?",
                options: [
                    "High growth potential",
                    "Customer-centric approach",
                    "Rigid organizational structures",
                    "Investment-driven models"
                ],
                answer: "Rigid organizational structures"
            },
            {
                question: "According to Kotler, what is the first step in good planning for SMEs?",
                options: [
                    "Setting financial goals",
                    "Market research",
                    "Hiring a skilled workforce",
                    "Product diversification"
                ],
                answer: "Market research"
            },
            {
                question: "What is the primary reason entrepreneurs typically exhibit a passion for their business?",
                options: [
                    "To achieve financial independence",
                    "To positively influence people's lives",
                    "To gain recognition in the market",
                    "To avoid traditional employment"
                ],
                answer: "To positively influence people's lives"
            },
            {
                question: "Which of the following traits is commonly shared by successful entrepreneurs?",
                options: [
                    "Avoiding risks entirely",
                    "Preferring structured job roles",
                    "Tolerant of ambiguity",
                    "Reluctant to network"
                ],
                answer: "Tolerant of ambiguity"
            },
            {
                question: "What is a common misconception about entrepreneurs?",
                options: [
                    "They avoid innovation",
                    "They are primarily motivated by financial gain",
                    "They are naturally conservative",
                    "They lack leadership skills"
                ],
                answer: "They are primarily motivated by financial gain"
            },
            {
                question: "What is a significant impact of entrepreneurial firms on society?",
                options: [
                    "They reduce job creation",
                    "They focus exclusively on profits",
                    "They improve quality of life with innovations",
                    "They rely entirely on government subsidies"
                ],
                answer: "They improve quality of life with innovations"
            },
            {
                question: "Why do some investors prefer experienced entrepreneurs over younger ones?",
                options: [
                    "Younger entrepreneurs have fewer innovative ideas",
                    "Older entrepreneurs are more likely to take risks",
                    "Experienced entrepreneurs have strong reputations and track records",
                    "Younger entrepreneurs lack financial motivation"
                ],
                answer: "Experienced entrepreneurs have strong reputations and track records"
            },
            {
                question: "What characteristic of startups allows them to adapt quickly to market changes?",
                options: [
                    "Customer-centric approach",
                    "Flexible structure",
                    "High investment levels",
                    "Traditional decision-making processes"
                ],
                answer: "Flexible structure"
            },
            {
                question: "What is one key goal for entrepreneurs during the initial stages of launching a business?",
                options: [
                    "Reducing competition",
                    "Securing profits and revenues quickly",
                    "Avoiding all forms of marketing",
                    "Achieving perfect product development"
                ],
                answer: "Securing profits and revenues quickly"
            },
            {
                question: "Which marketing barrier is commonly faced by SMEs?",
                options: [
                    "Over-specialization of expertise",
                    "Lack of external funding",
                    "Limited resources for marketing",
                    "Excessive reliance on large firms"
                ],
                answer: "Limited resources for marketing"
            },
            {
                question: "What type of marketing is often used by startups in their early stages?",
                options: [
                    "Responsive marketing",
                    "Mass media advertising",
                    "Exclusive niche marketing",
                    "Outbound telemarketing"
                ],
                answer: "Responsive marketing"
            },
            {
                question: "What differentiates startups from larger companies in terms of innovation?",
                options: [
                    "Startups have higher patent rates per employee",
                    "Startups avoid disrupting existing markets",
                    "Startups rely on fewer technological advancements",
                    "Startups lack customer-centric approaches"
                ],
                answer: "Startups have higher patent rates per employee"
            }
        ]
    },
    {
        key: 'Chapter2',
        name: 'Chapter 2',
        questions: [
            {
                question: "Entrepreneurial marketing emphasizes which mindset for navigating uncertainties?",
                options: [
                    "Hands-on and experimental",
                    "Fixed and procedural",
                    "Conservative and risk-averse",
                    "Analytical and slow-paced"
                ],
                answer: "Hands-on and experimental"
            },
            {
                question: "What is the main issue caused by marketing myopia?",
                options: [
                    "Overlooking customer needs",
                    "Excessive focus on competition",
                    "Ignoring market trends",
                    "Poor use of technology"
                ],
                answer: "Overlooking customer needs"
            },
            {
                question: "In the context of marketing blind spots, what does weak integration of online and offline marketing lead to?",
                options: [
                    "Increased customer loyalty",
                    "Inefficient resource allocation",
                    "Loss of market relevance",
                    "Better brand recognition"
                ],
                answer: "Loss of market relevance"
            },
            {
                question: "Why is flexibility and agility important in entrepreneurial marketing?",
                options: [
                    "To reduce costs",
                    "To quickly adapt to changing market conditions",
                    "To avoid customer feedback",
                    "To maintain consistent strategies"
                ],
                answer: "To quickly adapt to changing market conditions"
            },
            {
                question: "Which characteristic is NOT associated with entrepreneurial marketing?",
                options: [
                    "Resourcefulness in overcoming challenges",
                    "Heavy reliance on traditional methods",
                    "Innovation and creativity",
                    "Close customer engagement"
                ],
                answer: "Heavy reliance on traditional methods"
            },
            {
                question: "What is a common approach in entrepreneurial marketing to make the most of limited resources?",
                options: [
                    "Use of high-budget advertising",
                    "Creative and unconventional tactics",
                    "Hiring a large marketing team",
                    "Outsourcing all marketing activities"
                ],
                answer: "Creative and unconventional tactics"
            },
            {
                question: "What does the term \"marketing blind spot\" refer to?",
                options: [
                    "Overemphasis on customer feedback",
                    "Ignoring certain elements in marketing processes",
                    "Using outdated digital tools",
                    "Avoiding market segmentation"
                ],
                answer: "Ignoring certain elements in marketing processes"
            },
            {
                question: "Which of the following describes a \"disharmonious marketing and sales relationship\"?",
                options: [
                    "Complete reliance of sales on marketing",
                    "No connection between marketing and customer feedback",
                    "Conflict or misalignment between marketing and sales teams",
                    "Excessive integration of online and offline marketing"
                ],
                answer: "Conflict or misalignment between marketing and sales teams"
            },
            {
                question: "What role does customer-centricity play in entrepreneurial marketing?",
                options: [
                    "It avoids innovation in strategies",
                    "It focuses on aligning products with customer needs",
                    "It minimizes the impact of market feedback",
                    "It reduces direct customer interaction"
                ],
                answer: "It focuses on aligning products with customer needs"
            },
            {
                question: "What is a key characteristic of \"lack of humanity in marketing\"?",
                options: [
                    "Excessive focus on profits",
                    "Ignoring corporate social responsibility (CSR)",
                    "Overuse of digital marketing tools",
                    "Strong integration of customer-centric strategies"
                ],
                answer: "Ignoring corporate social responsibility (CSR)"
            },
            {
                question: "Which element distinguishes entrepreneurial marketing from traditional marketing?",
                options: [
                    "Segmenting and targeting established customer bases",
                    "Rigid methodologies and timelines",
                    "Innovation and agility in strategy execution",
                    "Dependence on financial metrics"
                ],
                answer: "Innovation and agility in strategy execution"
            },
            {
                question: "What is the primary focus of \"opportunity recognition\" in entrepreneurial marketing?",
                options: [
                    "Identifying unmet customer needs and market gaps",
                    "Developing high-budget marketing campaigns",
                    "Expanding into saturated markets",
                    "Increasing traditional media advertisements"
                ],
                answer: "Identifying unmet customer needs and market gaps"
            }
        ]
    },
    {
        key: 'Chapter3',
        name: 'Chapter 3',
        questions: [
            {
                question: "What is the focus of Kotler's perspective on entrepreneurial competition?",
                options: [
                    "Reducing market uncertainty through customer development",
                    "Avoiding resource constraints by raising large capital",
                    "Relying solely on established market strategies",
                    "Ignoring the importance of adaptability"
                ],
                answer: "Reducing market uncertainty through customer development"
            },
            {
                question: "What does the concept of collaborative competition involve?",
                options: [
                    "Competing by undercutting prices",
                    "Collaborating with competitors to achieve mutual benefits",
                    "Establishing monopolistic practices",
                    "Focusing solely on internal operations"
                ],
                answer: "Collaborating with competitors to achieve mutual benefits"
            },
            {
                question: "Which of the following is NOT one of the drivers of collaborative competition?",
                options: [
                    "Technology",
                    "Politics and legal issues",
                    "Cultural traditions",
                    "Market conditions"
                ],
                answer: "Cultural traditions"
            },
            {
                question: "What is a key lesson from the David and Goliath business strategy?",
                options: [
                    "Small businesses must rely on brute force to compete with large organizations",
                    "Disrupting the rules and using agility can help underdogs compete",
                    "Large companies always maintain an advantage",
                    "Underdogs must copy their larger competitors' strategies"
                ],
                answer: "Disrupting the rules and using agility can help underdogs compete"
            },
            {
                question: "What does Fujifilm's pivot to new industries exemplify?",
                options: [
                    "Failure to adapt leads to inevitable business closure",
                    "Collaboration with competitors is unnecessary for survival",
                    "Agility and innovation allow businesses to stay relevant in changing markets",
                    "Resource constraints should prevent market diversification"
                ],
                answer: "Agility and innovation allow businesses to stay relevant in changing markets"
            }
        ]
    },
    {
        key: 'Chapter5',
        name: 'Chapter 5',
        questions: [
            {
                question: "What is the definition of market-driven marketing?",
                options: [
                    "Influencing market structure and behavior to enhance competitive positioning",
                    "Reacting to the behavior of players in a specific market structure",
                    "Avoiding customer engagement to focus on internal processes",
                    "Focusing on disruptive strategies only"
                ],
                answer: "Reacting to the behavior of players in a specific market structure"
            },
            {
                question: "What is the definition of market-driving marketing?",
                options: [
                    "Adapting to current market behaviors without initiating changes",
                    "Influencing the market structure or behaviors of market players",
                    "Focusing solely on customer loyalty without innovation",
                    "Relying on existing trends for strategy"
                ],
                answer: "Influencing the market structure or behaviors of market players"
            },
            {
                question: "Which mindset involves identifying problems and finding solutions through calculated risks?",
                options: [
                    "Creativity and Innovation mindset",
                    "Leadership mindset",
                    "Entrepreneurial mindset",
                    "Traditional management mindset"
                ],
                answer: "Entrepreneurial mindset"
            },
            {
                question: "Which mindset emphasizes turning ideas into reality and value creation?",
                options: [
                    "Creativity mindset",
                    "Innovation mindset",
                    "Entrepreneurial mindset",
                    "Transformational leadership mindset"
                ],
                answer: "Innovation mindset"
            },
            {
                question: "What differentiates creativity from innovation?",
                options: [
                    "Creativity focuses on ideas, while innovation focuses on implementation and value creation",
                    "Creativity involves teamwork, while innovation is always individual",
                    "Creativity avoids risks, while innovation prioritizes cost reduction",
                    "Creativity is about execution, and innovation is about imagination"
                ],
                answer: "Creativity focuses on ideas, while innovation focuses on implementation and value creation"
            }
        ]
    },
    {
        key: 'Chapter6',
        name: 'Chapter 6',
        questions: [
            {
                question: "What is the key difference between the 4Ps and 7Ps in the marketing mix?",
                options: [
                    "4Ps focus on services, while 7Ps are for goods",
                    "7Ps include additional elements for services: People, Process, and Physical Evidence",
                    "4Ps are outdated and irrelevant",
                    "7Ps focus only on online marketing"
                ],
                answer: "7Ps include additional elements for services: People, Process, and Physical Evidence"
            },
            {
                question: "What does Integrated Marketing Communications (IMC) aim to achieve?",
                options: [
                    "A fragmented marketing message",
                    "Consistent and unified communication across all promotional tools",
                    "High-cost marketing strategies",
                    "Isolated offline and online campaigns"
                ],
                answer: "Consistent and unified communication across all promotional tools"
            },
            {
                question: "What is the primary goal of guerrilla marketing?",
                options: [
                    "High-budget advertising to reach a mass audience",
                    "Using creative, low-cost tactics to surprise and engage the target audience",
                    "Implementing traditional billboard campaigns",
                    "Targeting only online users"
                ],
                answer: "Using creative, low-cost tactics to surprise and engage the target audience"
            },
            {
                question: "What type of advertising uses buses and taxis to promote brands?",
                options: [
                    "Street furniture advertising",
                    "Transit advertising",
                    "Blimp advertising",
                    "In-game advertising"
                ],
                answer: "Transit advertising"
            },
            {
                question: "What are the three main promotional appeals in marketing?",
                options: [
                    "Create, Execute, Profit",
                    "Think, Feel, Do",
                    "Innovate, Advertise, Sell",
                    "Build, Promote, Analyze"
                ],
                answer: "Think, Feel, Do"
            },
            {
                question: "What is the primary focus of integrating marketing and finance departments?",
                options: [
                    "Isolating budget decisions to one department",
                    "Helping finance professionals understand departmental contexts",
                    "Reducing collaboration between departments",
                    "Avoiding communication about marketing costs"
                ],
                answer: "Helping finance professionals understand departmental contexts"
            },
            {
                question: "What is the role of flexibility in overcoming organizational rigidity?",
                options: [
                    "Maintaining current business processes",
                    "Converging various management functions and resources",
                    "Encouraging silos within departments",
                    "Limiting collaboration to essential activities"
                ],
                answer: "Converging various management functions and resources"
            },
            {
                question: "What does the \"communication mix\" include in marketing?",
                options: [
                    "Exclusive offline promotions only",
                    "A combination of offline and online promotion tools",
                    "Print advertisements exclusively",
                    "Social media ads only"
                ],
                answer: "A combination of offline and online promotion tools"
            },
            {
                question: "What is the benefit of integrating offline and online marketing efforts?",
                options: [
                    "Ensures marketing campaigns remain siloed",
                    "Allows for consistent messaging and wider reach",
                    "Reduces the effectiveness of offline promotions",
                    "Limits customer engagement to specific demographics"
                ],
                answer: "Allows for consistent messaging and wider reach"
            },
            {
                question: "Which marketing method is commonly used for out-of-home (OOH) advertising?",
                options: [
                    "SEO campaigns",
                    "Social media filters",
                    "Transit and street furniture advertising",
                    "Email marketing"
                ],
                answer: "Transit and street furniture advertising"
            },
            {
                question: "What is the primary advantage of blimp advertising?",
                options: [
                    "It is visible from a great distance and can cover large areas",
                    "It focuses on engaging specific demographics through social media",
                    "It is the most cost-effective form of online marketing",
                    "It replaces traditional billboard advertising completely"
                ],
                answer: "It is visible from a great distance and can cover large areas"
            }
        ]
    }
];

export default chapters;